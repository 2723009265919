<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <template v-if="!showDetails">
              <v-row>
                <v-col
                  v-for="form in seasonalForms"
                  :key="form.id"
                  class="text-center pointer hover rounded"
                  cols="auto"
                  @click="openFolder(form)"
                >
                  <v-icon
                    class="text-h1"
                    color="blue"
                  >
                    mdi-folder
                  </v-icon>
                  <div class="d-block">
                    {{ form.name }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-if="showDetails">
              <v-row class="align-center">
                <v-col cols="auto">
                  <v-btn
                    depressed
                    small
                    text
                    color="blue"
                    @click="back()"
                  >
                    <v-icon left>
                      mdi-chevron-right
                    </v-icon>
                    {{ $t('Back') }}
                  </v-btn>
                </v-col>

                <v-col cols>
                  {{ $t('Forms of year') }} {{ activeForm.name }}
                </v-col>
              </v-row>

              <v-row
                v-for="(detail, detailIndex) in formDetails"
                :key="detail.id"
                align="center"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                  lg="5"
                >
                  <v-row>
                    <v-col
                      cols="auto"
                      class="font-weight-bold"
                    >
                      {{ getSeasonName(detailIndex + 1, detail.is_yearly) }}
                    </v-col>

                    <v-col cols="12">
                      {{ $_date_format(detail.from_date) }} - {{ $_date_format(detail.to_date) }}
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  v-if="calcRemainDays(detail.end_date) <= 20"
                  cols="12"
                >
                  <form-fill-progress
                    :is-completed="detail.completed_at ? true: false"
                    :remain-days="calcRemainDays(detail.end_date)"
                  />
                </v-col>

                <v-col
                  v-if="detailIndex != formDetails.length - 1"
                  cols="12"
                >
                  <v-divider />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import FormFillProgress from './FormFillProgress.vue'

export default {
  components: {
    FormFillProgress,
  },

  data() {
    return {
      showDetails: false,
      seasonalForms: [],
      activeForm: {},
      formDetails: [],
    }
  },

  mounted() {
    this.getSeasonalForms()
  },

  methods: {
    getSeasonalForms() {
      axios.get('company-seasonal-form/client/').then(res => {
        this.seasonalForms = res.data
      })
    },

    openFolder(form) {
      this.activeForm = form
      let payload = { company_seasonal_form_id: Number(form.id) }

      axios.post(`company-seasonal-form-detail/client/`, payload).then(res => {
        this.formDetails = res.data
        this.showDetails = true
      })
    },

    back() {
      this.activeForm = {}
      this.showDetails = false
    },

    getSeasonName(num, isYearly) {
      if (num == 1) return this.$t('First Season')
      if (num == 2) return this.$t('Second Season')
      if (num == 3) return this.$t('Third Season')
      if (num == 4) return this.$t('Fourth Season')
      if (num == 5 && isYearly) return this.$t('Yearly Form')
    },

    calcRemainDays(endDate) {
      let today = Vue.moment(new Date())
      let dueDate = Vue.moment(endDate)
      return Number(dueDate.diff(today, 'days'))
    },
  },
}
</script>

<style lang="scss" scoped>
</style>